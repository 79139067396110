<template>
    <div class="user-setting">
        <el-alert
                v-if="!isRealName"
                style="width: 60vw;margin: 20px auto; "
                title="请尽快完善个人信息"
                description="个人信息将成为简历的一部分"
                type="info"
                :closable="true"
                :show-icon="true"
                :center="true"
        >
        </el-alert>
        <div class="setting-acount user-content">
            <div class="s-tab-nav">
                <h3>账号设置</h3>
                <div class="t-nav-list">
                    <div class="t-nav-item" :class="{'n-active':this.$route.path==='/user/settingInfo/userInfo'}" @click="routeTo('/user/settingInfo/userInfo')">
                        个人信息
                    </div>
                    <div class="t-nav-item" :class="{'n-active':this.$route.path==='/user/settingInfo/editUserPassword'}" @click="routeTo('/user/settingInfo/editUserPassword')">
                        修改密码
                    </div>
                </div>
            </div>
            <div class="info-warpper">
                <router-view @isRealName="realName" ></router-view>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    data(){
        return {
            isRealName:false
        }
    },
    methods: {
        realName(data){
            this.isRealName = data
        },
        routeTo(path) {
            this.$router.push({
                path
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "../../../assets/css/global.scss";
    .user-setting{
        height: 100%;
        margin-top: 20px;
        margin-bottom: 100px;
        .setting-acount{
            box-sizing: border-box;
            position: relative;
            min-height: 460px;
            padding-left: 225px;
            .s-tab-nav{
                width: 222px;
                height: 100%;
                position: absolute;
                background: #f8f8f8;
                top: 0;
                left: 0;
                bottom: 0;
                text-align: center;
                border-right: 2px solid #eef0f5;
                h3{
                    font-size: 20px;
                    font-weight: 400;
                    padding: 30px 0;
                    color: #414a60;
                }
                .t-nav-list{
                    .t-nav-item{
                        user-select: none;
                        height: 60px;
                        width: 100%;
                        line-height: 60px;
                        cursor: pointer;
                        font-size: 16px;
                        opacity: .5;
                        &.n-active{
                            background-color: #fff;
                            opacity: 1;
                            position: relative;
                            &::after{
                                content: "";
                                position: absolute;
                                height: 60px;
                                width: 3px;
                                background: $main;
                                right: -2px;
                                top: 0;
                            }
                        }
                    }
                }
            }
            .info-warpper{
                box-sizing: border-box;
                padding: 40px;
                width: 973px;
                min-height: 460px;
                background: #fff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
</style>